import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import HandDrawing from "../../../../../components/hand-drawing"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const answer = getAnswer({
    courseId: "reiseerinnerung",
    chapterId: "02-dichterwerkstatt",
    taskId: "hoelderlins-handschrift",
  })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/02-dichterwerkstatt/strophen-und-verse" />
      }
      navigation={navigation}
    >
      <Seo title="Hölderlins Handschrift nachzeichnen" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlins Handschrift nachzeichnen
        </Heading>
        <HandDrawing ratio={0.8} radius={3} saveData={answer} disabled />
        <Paragraph>
          Wow! Ein echtes Meisterwerk. Hölderlins Handschrift zu entziffern ist
          gar nicht so leicht. Man muss ganz genau hinsehen – und manchmal auch
          ein wenig raten.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
